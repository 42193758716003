import React from "react";
import config from "../../../data/config";
import { useHasScroll } from "has-scroll-hook";
import Logo from "../../../static/svg/logo.svg";
import "./HeaderEmpty.scss";

export default function HeaderEmpty() {
  const hasScroll = useHasScroll();

  return (
    <header
      className={`navbar is-transparent is-fixed-top ${
        hasScroll ? "is-scrolled" : ""
      }`}
      aria-label="main navigation"
    >
      <div className="navbar-brand">
        <a className="navbar-item" href={config.siteUrl}>
          <img src={Logo} className="is-blue-fill" />
        </a>
      </div>

      <div className="navbar-menu" id="navMenu">
        <div className="navbar-end">
          <div className="navbar-item is-hidden-mobile">
            <div className="ninja-toggle">
              <label htmlFor="theme-toggle-mobile">
                <span className="track">
                  <span className="track-inner"></span>
                  <span className="track-knob">
                    <i className="sun" data-feather="sun"></i>
                    <i className="moon" data-feather="moon"></i>
                  </span>
                </span>
              </label>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}
