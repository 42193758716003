import React from "react";
import Helmet from "react-helmet";
import favicon from "../../../static/favicon.png";
import config from "../../../data/config";
import HeaderEmpty from "../HeaderEmpty";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUp } from "@fortawesome/free-solid-svg-icons";
import { useHasScroll } from "has-scroll-hook";

import "../Layout/Layout.scss";
import "./Empty.scss";

export default function Empty({ children }) {
  const hasScroll = useHasScroll();
  return (
    <div>
      <Helmet>
        <meta name="description" content={config.siteDescription} />
        <link rel="icon" href={favicon} />
      </Helmet>
      <HeaderEmpty />
      {children}
      <div id="backtotop" className={`${hasScroll ? "visible" : ""}`}>
        <a href="#">
          <FontAwesomeIcon icon={faArrowUp} />
        </a>
      </div>
    </div>
  );
}
