import React from "react";
import Hero from "../../../static/svg/hero.svg";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebookF,
  faInstagram,
  faLinkedinIn,
  faGithub,
} from "@fortawesome/free-brands-svg-icons";
import "./NewsletterSuccess.scss";

const NewsletterSuccess = () => {
  return (
    <div className="hero main-hero is-fullheight is-default is-bold section-1">
      <img src={Hero} className="hero-shape is-active" />
      <div className="hero-body">
        <div className="container">
          <div className="columns is-vcentered hero-caption">
            <div className="column is-7">
              <h1 className="title is-2 is-bold is-active">Adriano Righi</h1>
              <h2 className="subtitle is-6 is-active">
                Especialista PHP e NodeJs
                <br />
                Tech Lead
                <br />
                DevOps
              </h2>
            </div>
            <div className="column is-4 is-offset-1">
              <h1 className="title is-2 is-bold has-text-white">Newsletter</h1>
              <h2 className="subtitle is-6 has-text-light">
                Muito obrigado por se cadastrar na minha Newsletter. Aproveite
                para me seguir nas redes sociais! Até mais!!
              </h2>
            </div>
          </div>
        </div>
      </div>
      <div className="hero-foot">
        <nav className="tabs">
          <div className="container">
            <ul className="socials">
              <li>
                <a className="social">
                  <FontAwesomeIcon icon={faInstagram} />
                </a>
              </li>
              <li>
                <a className="social">
                  <FontAwesomeIcon icon={faGithub} />
                </a>
              </li>
              <li>
                <a className="social">
                  <FontAwesomeIcon icon={faLinkedinIn} />
                </a>
              </li>
              <li>
                <a className="social">
                  <FontAwesomeIcon icon={faFacebookF} />
                </a>
              </li>
            </ul>
          </div>
        </nav>
      </div>
    </div>
  );
};

export default NewsletterSuccess;
